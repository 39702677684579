@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --blue-dark: #1c202c;
  --blue-light: #232534;
  --blue-accent: #007acc;
  --text-grey: #a4a4a4;
  --item-selected: #33394e;
}

body {
  background: var(--blue-light);
  background-repeat: initial;
  background-attachment: fixed;
  font-family: "Montserrat", sans-serif;
}

.top-menu {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  background-color: var(--blue-dark);
  color: var(--text-grey);
  align-items: center;
  font-size: 1.2vh;
  position: fixed;
  top: 0;
}

.top-menu p {
  margin-inline: 1vh;
  user-select: none;
}

.left-menu {
  height: 100%;
  width: 18vh;
  background-color: var(--blue-dark);
  color: white;
  font-size: 1.2vh;
  position: fixed;
  left: 0;
  top: 5vh;
  user-select: none;
}

.left-menu h3 {
  margin-inline: 1vh;
  margin-block: 1vh;
}

.left-menu div:first-of-type {
  padding-inline-start: 2vh;
}

.left-menu div {
  display: flex;
  flex-direction: row;
  padding-inline-start: 4vh;
  padding-block: 0.8vh;
  align-items: center;
}

.left-menu div:hover {
  cursor: pointer;
}

.left-menu div.active {
  background-color: var(--item-selected);
}

.left-menu div > svg {
  margin-inline-end: 0.8vh;
  width: 1.8vh;
  height: 1.8vh;
}

.files-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3vh;
  background-color: var(--blue-dark);
  color: white;
  font-size: 1.2vh;
  position: fixed;
  left: 18vh;
  top: 5vh;
  bottom: 5vh;
  user-select: none;
}

.files-menu div {
  display: flex;
  flex-direction: row;
  width: 13vh;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.files-menu div:hover {
  cursor: pointer;
}

.files-menu div.active {
  background-color: var(--item-selected);
}

.files-menu div > svg {
  margin-inline-end: 0.8vh;
  width: 1.8vh;
  height: 1.8vh;
}

.bot-menu {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  background-color: var(--blue-dark);
  color: var(--text-grey);
  align-items: center;
  font-size: 1.2vh;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  padding-inline-end: 2vh;
}

.social-icons-container {
  width: 18vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bot-menu svg {
  margin-inline: 0.8vh;
  width: 2.4vh;
  height: 2.4vh;
}

.bot-menu p{
  color: white;
}

.content {
  margin: 10vh auto auto 18vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.4vh;
}

.content-title {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 106vh;
  align-items: center;
}

.content-title img {
  width: 18vh;
}

.title-container {
  margin-block-end: 2vh;
  font-size: 3vh;
  width: 80vh;
  color: var(--text-grey);
}

.title-container p { 
  font-weight: 600;
  font-size: 3.6vh;
  text-shadow: 0.2vh 0.2vh 0.5vh rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  flex-direction: row;
  margin: auto;
}

.rectangle-left {
  display: flex;
  flex-direction: column;
  background-color: var(--blue-accent);
  height: 44vh;
  width: 32vh;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.rectangle-left p {
  margin-inline-start: 2vh;
  margin-inline-end: 8vh;
  font-size: 1.9vh;
}

.rectangle-left svg {
  width: 12vh;
  height: 12vh;
  margin-inline-end: 5vh;
}

.rectangle-right {
  display: flex;
  flex-direction: column;
  background-color: var(--blue-light);
  height: 44vh;
  width: 80vh;
  color: white;
  border-radius: 4vh 0vh 0vh 4vh;
  margin-inline-start: -6vh;
  border: 0.3vh solid var(--blue-accent);
  align-items: center;
  justify-content: center;
}

.rectangle-right-title {
  width: 60%;
}

.rectangle-right h3 {
  color: var(--blue-accent);
  margin-bottom: 2vh;
  font-size: 2.4vh;
}

.form-section {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  max-height: 42vh;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block-end: 1vh;
  margin-inline: 0.4vh;
}

.form-group span {
  display: flex;
  flex-direction: column;
  background: var(--blue-light);
  font-size: 1.2vh;
  padding: 0.4vh 0.6vh;
  white-space: nowrap;
  width: fit-content;
  margin-bottom: -1vh;
  margin-inline-start: 1vh;
  z-index: 1;
}

.form-field {
  font-size: 1.2vh;
  font-weight: 500;
  padding: 1vh 1.8vh;
  border-radius: 1vh;
  background: none;
  color: white;
  border: 0.2vh solid var(--text-grey);
}

.form-field:focus {
  border: 0.2vh solid var(--blue-accent) !important;
  outline: none;
}

.contact-me-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0.6vh 1vh;
  border: 0.2vh solid var(--text-grey);
  border-radius: 1.6vh;
  margin-block-start: 2vh;
  font-size: 1.2vh;
  user-select: none;
}

.contact-me-btn:hover {
  cursor: pointer;
  border: 0.2vh solid var(--blue-accent);
}

.contact-me-btn svg {
  width: 1.4vh;
  height: 1.4vh;
  margin-inline-end: 1vh;
}

.about-content {
  margin: 10vh auto 8vh 18vh;
  display: flex;
  font-size: 1.6vh
}

/* Import Prism.js dark theme */
@import "https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/themes/prism-tomorrow.min.css";

/* Override Prism.js background color */
pre[class*="language-"] {
  background: none !important;
  padding: 0;
  margin: 0;
}

code[class*="language-"] {
  background: none !important;
}

pre.line-numbers {
  overflow: hidden;
  z-index: -1;
}

.resume-content {
  margin: 10vh auto 8vh 18vh;
  display: flex;
  font-size: 1.6vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--blue-accent);
}

.resume-content svg {
  width: 40vh;
  margin: auto;
}

